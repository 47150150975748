import React from "react";
import "../assets/css/Thanks.css";

const Thanks = () => {
  return (
    <div className="Thanks">
      <h2>Vielen Dank für deine Teilnahme an unserer Schnitzeljagd.</h2>

      <p>Sie erhalten in Kürze eine Bestätigungsmail für Ihre Teilnahme.</p>
    </div>
  );
};
export default Thanks;
