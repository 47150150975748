import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Form.css";
import axios from "axios";
import emailjs from "@emailjs/browser";

const Form = ({ handleContinue }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem("selectedOptions")) || Array(10).fill("")
  );
  const [kuenstlerhaus_festsaal, setKuenstlerhaus_festsaal] = useState(
    localStorage.getItem("kuenstlerhaus_festsaal") || ""
  );
  const [kuenstlerhaus_kulturkellerei, setKuenstlerhaus_kulturkellerei] =
    useState(localStorage.getItem("kuenstlerhaus_kulturkellerei") || "");
  const [neues_museum, setNeues_museum] = useState(
    localStorage.getItem("neues_museum") || ""
  );
  const [katharinenruine, setKatharinenruine] = useState(
    localStorage.getItem("katharinenruine") || ""
  );
  const [club_stereo, setClub_stereo] = useState(
    localStorage.getItem("club_stereo") || ""
  );
  const [korns, setKorns] = useState(localStorage.getItem("korns") || "");
  const [kornmarkt, setKornmarkt] = useState(
    localStorage.getItem("kornmarkt") || ""
  );
  const [kuenstlerhaus_kulturgarten, setKuenstlerhaus_kulturgarten] = useState(
    localStorage.getItem("kuenstlerhaus_kulturgarten") || ""
  );
  const [eingang_neues_museum, setEingang_neues_museum] = useState(
    localStorage.getItem("eingang_neues_museum") || ""
  );
  const [club_stereo_2, setClub_stereo_2] = useState(
    localStorage.getItem("club_stereo_2") || ""
  );
  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstName") || ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("lastName") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");

  useEffect(() => {
    localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
    localStorage.setItem("kuenstlerhaus_festsaal", kuenstlerhaus_festsaal);
    localStorage.setItem(
      "kuenstlerhaus_kulturkellerei",
      kuenstlerhaus_kulturkellerei
    );
    localStorage.setItem("neues_museum", neues_museum);
    localStorage.setItem("katharinenruine", katharinenruine);
    localStorage.setItem("club_stereo", club_stereo);
    localStorage.setItem("korns", korns);
    localStorage.setItem("kornmarkt", kornmarkt);
    localStorage.setItem(
      "kuenstlerhaus_kulturgarten",
      kuenstlerhaus_kulturgarten
    );
    localStorage.setItem("eingang_neues_museum", eingang_neues_museum);
    localStorage.setItem("club_stereo_2", club_stereo_2);
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("email", email);
  }, [
    selectedOptions,
    kuenstlerhaus_festsaal,
    kuenstlerhaus_kulturkellerei,
    neues_museum,
    katharinenruine,
    club_stereo,
    korns,
    kornmarkt,
    kuenstlerhaus_kulturgarten,
    eingang_neues_museum,
    club_stereo_2,
    firstName,
    lastName,
    email,
  ]);

  const isFormComplete = () => {
    return (
      selectedOptions.every((option) => option !== "") &&
      firstName !== "" &&
      lastName !== "" &&
      email !== ""
    );
  };

  const handleSelectChange = (index, value) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[index] = value;
    setSelectedOptions(updatedSelectedOptions);
  };

  const correctAnswers = [
    "ehrlichkeit",
    "ehrlichkeit",
    "ehrlichkeit",
    "ehrlichkeit",
    "ehrlichkeit",
    "ehrlichkeit",
    "ehrlichkeit",
    "ehrlichkeit",
    "ehrlichkeit",
    "ehrlichkeit",
  ];

  localStorage.removeItem("selectedOptions");
  localStorage.removeItem("kuenstlerhaus_festsaal");
  localStorage.removeItem("kuenstlerhaus_kulturkellerei");
  localStorage.removeItem("neues_museum");
  localStorage.removeItem("katharinenruine");
  localStorage.removeItem("club_stereo");
  localStorage.removeItem("korns");
  localStorage.removeItem("kornmarkt");
  localStorage.removeItem("kuenstlerhaus_kulturgarten");
  localStorage.removeItem("eingang_neues_museum");
  localStorage.removeItem("club_stereo_2");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("email");

  const calculateScore = () => {
    let score = 0;
    for (let i = 0; i < selectedOptions.length; i++) {
      if (selectedOptions[i] === correctAnswers[i]) {
        score++;
      }
    }
    return score;
  };

  const form = useRef();
  const sendEmail = (e) => {
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log("Message Sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const submit_form = (e) => {
    e.preventDefault();

    sendEmail();
    handleContinue();

    setTimeout(function () {
      window.location.replace("https://www.psd-nuernberg.de/grundwerte");
    }, 5000);

    if (!isFormComplete()) {
      alert("Bitte füllen Sie alle Felder aus.");
      return;
    }

    if (
      selectedOptions.includes("") ||
      firstName === "" ||
      lastName === "" ||
      email === ""
    ) {
      alert("Bitte füllen Sie alle Felder aus.");
      return;
    }

    const score = calculateScore();
    console.log("Your score:", score);

    // axios
    //   .post("https://psd-schnitzeljagd.fourplex.de/", {
    //     kuenstlerhaus_festsaal: kuenstlerhaus_festsaal,
    //     kuenstlerhaus_kulturkellerei: kuenstlerhaus_kulturkellerei,
    //     neues_museum: neues_museum,
    //     katharinenruine: katharinenruine,
    //     club_stereo: club_stereo,
    //     korns: korns,
    //     kornmarkt: kornmarkt,
    //     kuenstlerhaus_kulturgarten: kuenstlerhaus_kulturgarten,
    //     eingang_neues_museum: eingang_neues_museum,
    //     club_stereo_2: club_stereo_2,
    //     firstName: firstName,
    //     lastName: lastName,
    //     email: email,
    //     score: score,
    //   })
    //   .then(() => {
    //     console.log("success");
    //   });
  };

  return (
    <>
      <form ref={form} onSubmit={submit_form}>
        <div className="card card-1">
          <h2>Wo ist welcher Wert zu finden?</h2>

          <div className="select-box">
            <label htmlFor="kuenstlerhaus_festsaal">
              KÜNSTLERHAUS (Festsaal)
            </label>
            <select
              id="kuenstlerhaus_festsaal"
              onChange={(event) => {
                setKuenstlerhaus_festsaal(event.target.value);
                handleSelectChange(0, event.target.value);
              }}
              value={kuenstlerhaus_festsaal}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="kuenstlerhaus_kulturkellerei">
              KÜNSTLERHAUS (Kulturkellerei)
            </label>
            <select
              id="kuenstlerhaus_kulturkellerei"
              onChange={(event) => {
                setKuenstlerhaus_kulturkellerei(event.target.value);
                handleSelectChange(1, event.target.value);
              }}
              value={kuenstlerhaus_kulturkellerei}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="neues_museum">NEUES MUSEUM</label>
            <select
              id="neues_museum"
              onChange={(event) => {
                setNeues_museum(event.target.value);
                handleSelectChange(2, event.target.value);
              }}
              value={neues_museum}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="katharinenruine">KATHARINENRUINE</label>
            <select
              id="katharinenruine"
              onChange={(event) => {
                setKatharinenruine(event.target.value);
                handleSelectChange(3, event.target.value);
              }}
              value={katharinenruine}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="club_stereo">CLUB STEREO</label>
            <select
              id="club_stereo"
              onChange={(event) => {
                setClub_stereo(event.target.value);
                handleSelectChange(4, event.target.value);
              }}
              value={club_stereo}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>
        </div>
        <div className="card card-2">
          <h2>Wo ist welcher Wert zu finden?</h2>

          <div className="select-box">
            <label htmlFor="korns">KORN'S</label>
            <select
              id="korns"
              onChange={(event) => {
                setKorns(event.target.value);
                handleSelectChange(5, event.target.value);
              }}
              value={korns}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="kornmarkt">KORNMARKT</label>
            <select
              id="kornmarkt"
              onChange={(event) => {
                setKornmarkt(event.target.value);
                handleSelectChange(6, event.target.value);
              }}
              value={kornmarkt}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="kuenstlerhaus_kulturgarten">
              KÜNSTLERHAUS (Kulturgarten)
            </label>
            <select
              id="kuenstlerhaus_kulturgarten"
              onChange={(event) => {
                setKuenstlerhaus_kulturgarten(event.target.value);
                handleSelectChange(7, event.target.value);
              }}
              value={kuenstlerhaus_kulturgarten}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="eingang_neues_museum">EINGANG NEUES MUSEUM</label>
            <select
              id="eingang_neues_museum"
              onChange={(event) => {
                setEingang_neues_museum(event.target.value);
                handleSelectChange(8, event.target.value);
              }}
              value={eingang_neues_museum}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="club_stereo_2">CLUB STEREO</label>
            <select
              id="club_stereo_2"
              onChange={(event) => {
                setClub_stereo_2(event.target.value);
                handleSelectChange(9, event.target.value);
              }}
              value={club_stereo_2}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="wert_6"
                disabled={selectedOptions.includes("wert_6")}
              >
                #wert_6
              </option>
              <option
                value="wert_7"
                disabled={selectedOptions.includes("wert_7")}
              >
                #wert_7
              </option>
              <option
                value="wert_8"
                disabled={selectedOptions.includes("wert_8")}
              >
                #wert_8
              </option>
              <option
                value="wert_9"
                disabled={selectedOptions.includes("wert_9")}
              >
                #wert_9
              </option>
              <option
                value="wert_10"
                disabled={selectedOptions.includes("wert_10")}
              >
                #wert_10
              </option>
            </select>
          </div>
        </div>
        ;
        <div className="card card-3">
          <h2>
            Fertig? <br /> Prima! Zeit dir die Gewinnchancen zu sichern ...
          </h2>
          <div className="select-box input-box">
            <input
              type="text"
              id="firstName"
              name="to_name"
              required
              placeholder="Vorname"
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              value={firstName}
            />
          </div>

          <div className="select-box input-box">
            <input
              type="text"
              id="lastName"
              required
              placeholder="Nachname"
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              value={lastName}
            />
          </div>

          <div className="select-box input-box">
            <input
              type="email"
              id="email"
              name="recipient"
              required
              placeholder="E-Mail"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
            />
          </div>

          <div className="conditions-box">
            <input type="checkbox" id="checkbox" required />
            <label htmlFor="conditions">
              Ich bin mit den <Link>Teilnahmebedingungen</Link> und
              <Link
                to="https://www.psd-nuernberg.de/service/rechtliche-hinweise/datenschutzhinweis.html"
                target="_blank"
              >
                &nbsp; Datenschutzbestimmungen &nbsp;
              </Link>
              einverstanden.
            </label>
          </div>
          <button type="submit" disabled={!isFormComplete()}>
            {isFormComplete() ? (
              <p>Absenden</p>
            ) : (
              <p>Bitte alle Felder ausfüllen</p>
            )}
          </button>
        </div>
      </form>
    </>
  );
};
export default Form;
