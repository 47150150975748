import React from "react";
import "../assets/css/Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div id="logos">
        <img src="/images/NBG-POP.png" alt="" />
        <img src="/images/PSD_Bank_logo-1.png" alt="" />
      </div>
      <div>
        <h3>Mehr wir für dich.</h3>
        <Link to="/conditions">Teilnahmebedingungen</Link>
        <Link
          to="https://www.psd-nuernberg.de/service/rechtliche-hinweise/datenschutzhinweis.html"
          target="_blank"
        >
          Datenschutzbestimmungen
        </Link>
        <Link
          to="https://www.psd-nuernberg.de/service/rechtliche-hinweise/impressum.html"
          target="_blank"
        >
          Impressum
        </Link>
      </div>
    </footer>
  );
};
export default Footer;
