import React from "react";
import "../assets/css/Home.css";

const Home = () => {
  return (
    <div className="Home">
      <img src="/images/PSD_Bank_logo-1.png" alt="" />
      <h1>Wertejagd</h1>
      <p>
        Die PSD Bank Nürnberg hat 10 Grundwerte verankert, nachdem ihr handeln
        ausgerichtet ist. Finde diese innerhalb der Festival-Locations, ordne
        diese idealerweise richtig ein und erhalte damit die Chance auf tolle
        Gewinne.
        <br />
        <br />
        VIEL SPAß!
      </p>
    </div>
  );
};
export default Home;
