import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./components/Home";
import Form from "./components/Form";
import Thanks from "./components/Thanks";
import Footer from "./components/Footer";

function App() {
  const [shiftValue, setShiftValue] = useState(0);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(true);

  useEffect(() => {
    if (shiftValue === 0) {
      setShowBackButton(false);
    } else if (shiftValue === -60) {
      setShowContinueButton(false);
      setShowBackButton(true);
    } else {
      setShowBackButton(true);
      setShowContinueButton(true);
    }
  }, [shiftValue]);

  const handleBack = () => {
    if (shiftValue < 0) {
      setShiftValue(shiftValue + 20);
    }
  };

  const handleContinue = () => {
    if (shiftValue > -80) {
      setShiftValue(shiftValue - 20);
    }
  };

  return (
    <div className="App">
      <img id="yellowish-line" src="/images/yellowish-line.svg" alt="" />
      <Router>
        <div
          className="main-container"
          style={{ transform: `translateX(${shiftValue}%)` }}
        >
          <Home />
          <Form handleContinue={handleContinue} />
          <Thanks />
        </div>
        <div className="controls">
          <button
            onClick={handleBack}
            style={{ display: showBackButton ? "block" : "none" }}
          >
            Zurück
          </button>
          <button
            onClick={handleContinue}
            style={{ display: showContinueButton ? "block" : "none" }}
          >
            {shiftValue === 0 ? "Jetzt starten" : "Weiter"}
          </button>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
